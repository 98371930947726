<template>
    <div>
        <div class="header">
            <van-icon name="arrow-left" @click="$router.push({path: '/self/settings'})"/>
            <div>数据</div>
        </div>
        <div style="font-size:18px;color:red">个人信息</div>
        <van-form @submit="onSubmit">
            <van-field
                v-model="personalMsg.username"
                name="username"
                label="姓名"
                placeholder="姓名"
            />
            <van-field
                v-model="personalMsg.nickname"
                name="nickname"
                label="昵称"
                placeholder="昵称"
            />
            <van-field
                v-model="personalMsg.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
             <van-uploader :after-read="afterRead" />
            <van-field
                v-model="personalMsg.wxcode"
                name="wxcode"
                label="微信号"
                placeholder="微信号"
            />
             <van-field
                v-model="personalMsg.phone"
                name="phone"
                label="手机"
                placeholder="手机"
            />
            <van-field
                v-model="personalMsg.email"
                name="email"
                label="邮箱"
                placeholder="邮箱"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">更改</van-button>
            </div>
        </van-form>
        <div style="font-size:18px;color:red">收款人信息</div>
        <van-form @submit="onSubmitPayInfo">
            <van-field
                v-model="payInfobak.username"
                name="username"
                label="姓名"
                placeholder="姓名"
            />
            <van-field
                v-model="payInfobak.nickname"
                name="nickname"
                label="昵称"
                placeholder="昵称"
            />
                    <van-uploader :after-read="afterReada" />
            <van-field
                v-model="payInfobak.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
     
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">更改</van-button>
            </div>
        </van-form>
        <div style="font-size:18px;color:red">零钱/零钱通</div>
        <van-form @submit="onSubmitFund">
            <van-field
                v-model="fundData.coin"
                name="coin"
                label="零钱"
                placeholder="零钱"
            />
            <van-field
                v-model="fundData.coinCash"
                name="coinCash"
                label="零钱通"
                placeholder="零钱通"
            />
            <van-field
                v-model="fundData.shouyi"
                name="shouyi"
                label="累计收益"
                placeholder="累计收益"
            />
            <van-field
                v-model="fundData.zuorishouyi"
                name="zuorishouyi"
                label="昨日收益"
                placeholder="昨日收益"
            /> 
            <van-field
                v-model="fundData.danbixiane"
                name="danbixiane"
                label="单笔限额"
                placeholder="单笔限额"
            /> 
            <van-field
                v-model="fundData.meirixiane"
                name="meirixiane"
                label="每日限额"
                placeholder="每日限额"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">更改</van-button>
            </div>
        </van-form>
        <div style="font-size:18px;color:red">银行卡</div>
        <div v-for="bank in bankList" :key="bank.id" class="bankcon">
            <div>{{bank.title}}{{bank.type}}-{{ bank.no }}</div>
            <van-icon name="cross" @click="delBank(bank.id)" />
        </div>
        <div style="font-size:18px;color:red">添加银行卡</div>
        <van-form @submit="onSubmitNewBank">
            <van-field name="radio" label="卡类型">
                <template #input>
                    <van-radio-group v-model="newBankId" direction="horizontal">
                        <van-radio :name="bank.id" v-for="bank in bankDefaultList" :key="bank.id">{{ bank.title }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="newBankNo"
                name="newBankNo"
                label="卡号"
                placeholder="卡号"
            />
            <van-field name="isout" label="是否超额">
                <template #input>
                    <van-radio-group v-model="isOut" direction="horizontal">
                        <van-radio :name="true">是</van-radio>
                        <van-radio :name="false">否</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">添加</van-button>
            </div>
        </van-form>
        <div style="font-size:18px;color:red">账单</div>
        <div v-for="bill in billList" :key="bill.id" class="bill">
            <div>{{ bill.text }}</div>
            <div class="value">{{ bill.value }}</div>
            <van-icon name="cross" @click="delBill(bill.id)" />
        </div>
        <div style="font-size:18px;color:red">添加账单</div>
        <van-form @submit="onSubmitNewBill">
            <van-field
                v-model="bill.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
                        <van-uploader :after-read="afterReadb" />
            <van-field
                v-model="bill.text"
                name="avatar"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="bill.value"
                name="avatar"
                label="值"
                placeholder="值"
            />
            <van-field
                v-model="bill.transferTime"
                name="avatar"
                label="转账时间"
                 @click="showDatePicker = true"
                placeholder="转账时间"
            />
            <van-field
                v-model="bill.incomeTime"
                name="avatar"
                label="收款时间"
                 @click="showTimePicker = true"
                placeholder="收款时间"
            />
  	<van-popup v-model:show="showDatePicker" round position="bottom">          
<van-datetime-picker
  v-model="currentDate"
  type="datetime"
  title="选择年月日"
  @confirm="showDatePicker = false"
  @cancel="showDatePicker = false" 
  :min-date="minDate"
  :max-date="maxDate"
  @change="dateChange"
/>

	</van-popup> 
	<van-popup v-model:show="showTimePicker" round position="bottom">          
<van-datetime-picker
  v-model="currentTime"
  type="datetime"
  title="选择年月日"
  @confirm="showTimePicker = false"
  @cancel="showTimePicker = false" 
  :min-date="minDate"
  :max-date="maxDate"
  @change="timeChange"
/>

	</van-popup>
            <van-field name="radio" label="是否零钱">
                <template #input>
                    <van-radio-group v-model="bill.type" direction="horizontal">
                        <van-radio name="1">是</van-radio>
                        <van-radio name="2">否</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field name="radio" label="银行卡" v-if="bill.type == 2">
                <template #input>
                    <van-radio-group v-model="bill.bankId">
                        <van-radio :name="bank.id" v-for="bank in bankList" :key="bank.id">{{ bank.title }}{{ bank.no | banknofilter }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="bill.number"
                name="avatar"
                label="转账单号"
                placeholder="转账单号"
                @click="suiji"
            />
            <van-field
                v-model="bill.balance"
                name="avatar"
                label="零钱余额"
                placeholder="零钱余额"
            />
            <van-field name="zrtype" label="转入转出">
                <template #input>
                    <van-radio-group v-model="bill.zrtype" direction="horizontal">
                        <van-radio name="1">转出</van-radio>
                        <van-radio name="2">转入</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
           
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">添加</van-button>
            </div>
        </van-form>
        <div style="font-size:18px;color:red">添加零钱通</div>
        <van-form @submit="onSubmitLingqiantong">
            <div>第1条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data1.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data1.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-uploader :after-read="afterRead1" />
            <van-field
                v-model="lingqiantong.data1.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data1.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            />
                        <van-field
                v-model="lingqiantong.data1.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-field
                v-model="lingqiantong.data1.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijia"
            />
            <van-field
                v-model="lingqiantong.data1.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data1.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            <div>第2条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data2.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data2.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-uploader :after-read="afterRead2" />
            <van-field
                v-model="lingqiantong.data2.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data2.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            />
                        <van-field
                v-model="lingqiantong.data2.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-field
                v-model="lingqiantong.data2.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijib"
            />
            <van-field
                v-model="lingqiantong.data2.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data2.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            <div>第3条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data3.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data3.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data3.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            />
                        <van-field
                v-model="lingqiantong.data3.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-field
                v-model="lingqiantong.data3.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-uploader :after-read="afterRead3" />
            <van-field
                v-model="lingqiantong.data3.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijic"
            />
            <van-field
                v-model="lingqiantong.data3.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data3.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            <div>第4条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data4.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data4.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data4.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            />
                        <van-field
                v-model="lingqiantong.data4.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-field
                v-model="lingqiantong.data4.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-uploader :after-read="afterRead4" />
            <van-field
                v-model="lingqiantong.data4.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijid"
            />
            <van-field
                v-model="lingqiantong.data4.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data4.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            <div>第5条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data5.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data5.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data5.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            />
                        <van-field
                v-model="lingqiantong.data5.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-field
                v-model="lingqiantong.data5.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-uploader :after-read="afterRead5" />
            <van-field
                v-model="lingqiantong.data5.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijie"
            />
            <van-field
                v-model="lingqiantong.data5.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data5.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            <div>第6条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data6.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data6.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data6.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            />
                                    <van-field
                v-model="lingqiantong.data6.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-field
                v-model="lingqiantong.data6.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-uploader :after-read="afterRead6" />
            <van-field
                v-model="lingqiantong.data6.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijif"
            />
            <van-field
                v-model="lingqiantong.data6.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data6.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            <div>第7条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data7.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data7.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data7.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            />
                        <van-field
                v-model="lingqiantong.data7.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-uploader :after-read="afterRead7" />
            <van-field
                v-model="lingqiantong.data7.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-field
                v-model="lingqiantong.data7.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijig"
            />
            <van-field
                v-model="lingqiantong.data7.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data7.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            <div>第8条</div>
            <van-field name="radio" label="类型">
                <template #input>
                    <van-radio-group v-model="lingqiantong.data8.type" direction="horizontal">
                        <van-radio name="1" >微信转账</van-radio>
                        <van-radio name="2" >扫二维码付款</van-radio>
                        <van-radio name="3" >收益入账</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                v-model="lingqiantong.data8.text"
                name="newBankNo"
                label="标题"
                placeholder="标题"
            />
            <van-field
                v-model="lingqiantong.data8.name"
                name="newBankNo"
                label="对象"
                placeholder="对象"
            /> 
            <van-field
                v-model="lingqiantong.data8.times"
                name="newBankNo"
                label="时间"
                placeholder="时间"
            />
            <van-field
                v-model="lingqiantong.data8.avatar"
                name="avatar"
                label="头像"
                placeholder="头像"
            />
            <van-uploader :after-read="afterRead8" />
            <van-field
                v-model="lingqiantong.data8.number"
                name="avatar"
                label="订单号"
                placeholder="订单号"
                @click="suijih"
            />
            <van-field
                v-model="lingqiantong.data8.value"
                name="newBankNo"
                label="数值"
                placeholder="数值"
            />
            <van-field
                v-model="lingqiantong.data8.remain"
                name="newBankNo"
                label="余额"
                placeholder="余额"
            />
            
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">添加</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Dialog } from 'vant';
import { DatePicker } from 'vant';
import dayjs from 'dayjs';
    export default {
        data() {
            return {
                showDatePicker: false,
                showTimePicker: false,
                      randomNumber: null,
                personalMsg: {
                    username: '',
                    nickname: '',
                    avatar: '',
                    wxcode: '',
                    phone: '',
                    email: ''
                },
                payInfobak: {
                    username: '',
                    nickname: '',
                    avatar: ''
                },
                fundData: {
                    coin: '',
                    coinCash: '',
                    shouyi: '2319.73',
                    zuorishouyi: '17.36',
                    danbixiane:"40000.00",
                    meirixiane:"80000.00"
                },
                newBankId: '',
                newBankNo: '',
                isOut: false,
                bill: {
                    avatar: '',
                    text: '',
                    value: '',
                    transferTime: '',
                    incomeTime: '',
                    type: '',
                    bankId: '',
                    number: '',
                    balance: '',
                    zrtype: ''
                },
              minDate: new Date(2020, 0, 1),
              maxDate: new Date(2025, 10, 1),
              currentDate: new Date(),
              currentTime: new Date(),
              lingqiantong: {
                data1: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''
                },
                data2: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''

                },
                data3: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''

                },
                data4: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''

                },
                data5: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''

                },
                data6: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''

                },
                data7: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''

                },
                data8: {
                    type: '',
                    text: '',
                    value: '',
                    remain: '',
                    avatar: ''

                },
                
              }
            };
        },
        filters: {
            banknofilter(no) {
  // 处理逗号的情况
  let processedStr = no.replace(/,/g, '');

  if (processedStr.length < 4) {
    return processedStr;
  }
  return processedStr.slice(-4);
            }
            
            
        },
        computed: {
            ...mapState({
                personal: state => state.userInfo,
                payInfo: state => state.payInfo,
                fund: state => state.fund,
                bankDefaultList: state => state.bankDefaultList,
                billList: state => state.billList
            }),
            ...mapGetters([
                'bankList'
            ])
        },
        mounted() {
            
                     var date = new Date()
                    let month = date.getMonth() + 1;
                    month = month < 10? '0' + month : month;
                    let year = date.getFullYear();
                    let day = date.getDate();
                    day = day < 10? '0' + day : day;
                    let hours = date.getHours();
                    hours = hours < 10? '0' + hours : hours;
                    let minutes = date.getMinutes();
                    minutes = minutes < 10? '0' + minutes : minutes;
                    let seconds = date.getSeconds();
                    seconds = seconds < 10? '0' + seconds : seconds;
                    
                    const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;

    	                this.bill.transferTime = formattedDate
            
    	             //   this.bill.transferTime =  year+'年'+month+'月'+day+'日' + " "+hours +":"+minutes+":"+getSeconds
    	                this.bill.incomeTime = formattedDate
            this.personalMsg = this.personal
            this.payInfobak = this.payInfo
            this.fundData = {shouyi: '2319.73', zuorishouyi: '17.36' , danbixiane:"40000.00",
                    meirixiane:"80000.00", ...this.fund }
            if(localStorage.getItem('lingqiantong')) {
                const data = JSON.parse(localStorage.getItem('lingqiantong'))
                data.data1.time = dayjs().format('YYYY-MM-DD HH:mm:ss')
                data.data2.time = dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
                data.data3.time = dayjs().subtract(2, 'day').format('YYYY-MM-DD HH:mm:ss')
                data.data4.time = dayjs().subtract(4, 'day').format('YYYY-MM-DD HH:mm:ss')
                data.data5.time = dayjs().subtract(5, 'day').format('YYYY-MM-DD HH:mm:ss')
                data.data6.time = dayjs().subtract(6, 'day').format('YYYY-MM-DD HH:mm:ss')
                data.data7.time = dayjs().subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss')
                data.data8.time = dayjs().subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss')
                localStorage.setItem('lingqiantong', JSON.stringify(data))
                this.lingqiantong = data
            } else {
                this.lingqiantong.data1 = {
                    type: '3',
                    avatar: 'data',
                    text: '收益入账',
                    value: '6.66',
                    times: '15:06:01',
                    remain: '107890.00',
                    number:"89202"+this.generateRandomNumbera()
                }
                this.lingqiantong.data2 = {
                    type: '3',
                     avatar: 'data',
                    text: '收益入账',
                    value: '6.66',
                    times: '15:06:01',
                    remain: '356132.50',
                    number:"89202"+this.generateRandomNumbera()
                }
                this.lingqiantong.data3 = {
                    type: '3',
                     avatar: 'data',
                    text: '收益入账',
                    value: '6.66',
                    times: '15:06:01',
                    remain: '356132.50',
                    number:"89202"+this.generateRandomNumbera()
                }
                this.lingqiantong.data4 = {
                    type: '1',
                     avatar: 'data',
                    text: '微信转账',
                    value: '190000.00',
                    times: '15:06:01',
                    remain: '356132.50',
                    number:"10001"+this.generateRandomNumbera()
                }
                this.lingqiantong.data5 = {
                    type: '2',
                     avatar: 'data',
                    text: '扫二维码付款',
                    value: '100000.00',
                    times: '15:06:01',
                    remain: '356132.50',
                    number:"10001"+this.generateRandomNumbera()
                }
                this.lingqiantong.data6 = {
                    type: '1',
                    text: '微信转账',
                     avatar: 'data',
                    value: '150000.00',
                    times: '15:06:01',
                    remain: '356132.50',
                    number:"10001"+this.generateRandomNumbera()
                }
                this.lingqiantong.data7 = {
                    type: '3',
                     avatar: 'data',
                    text: '收益入账',
                    value: '6.66',
                    times: '15:06:01',
                    remain: '356132.50',
                    number:"89202"+this.generateRandomNumbera()
                }
                this.lingqiantong.data8 = {
                    type: '2',
                     avatar: 'data',
                    text: '扫二维码付款',
                    value: '60000.00',
                    times: '15:06:01',
                    remain: '656132.50',
                    number:"10001"+this.generateRandomNumbera()
                }
            }

 
            
            
        },
        methods: {
            ...mapMutations([
                'setUserInfo',
                'setPayInfo',
                'setFund',
                'setBankList',
                'addBill',
                'delBillList'
            ]),
            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.personalMsg.avatar = file.content;
               console.log(file);
             },
             generateRandomNumber() {
                  function getRandomDigit() {
                    return Math.floor(Math.random() * 10);
                  }
            
                  let randomNumberStr = "";
                  for (let i = 0; i < 33; i++) {
                    randomNumberStr += getRandomDigit();
                  }
                  return randomNumberStr;
                },
                generateRandomNumbera() {
                  function getRandomDigit() {
                    return Math.floor(Math.random() * 10);
                  }
            
                  let randomNumberStr = "";
                  for (let i = 0; i < 28; i++) {
                    randomNumberStr += getRandomDigit();
                  }
                  return randomNumberStr;
                },
             dateChange() {
             
                  if(this.currentDate){
                    var date = this.currentDate;
                    let month = date.getMonth() + 1;
                    month = month < 10? '0' + month : month;
                    let year = date.getFullYear();
                    let day = date.getDate();
                    day = day < 10? '0' + day : day;
                    let hours = date.getHours();
                    hours = hours < 10? '0' + hours : hours;
                    let minutes = date.getMinutes();
                    minutes = minutes < 10? '0' + minutes : minutes;
                    var date1 =  new Date()
                    let seconds = date1.getSeconds();
                    seconds = seconds < 10? '0' + seconds : seconds;
                    
                    const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;

    	                this.bill.transferTime = formattedDate
                  }
             },             timeChange() {
                 
                  if(this.currentTime){
                     var date = this.currentTime
                      let month = date.getMonth() + 1;
                    month = month < 10? '0' + month : month;
                    let year = date.getFullYear();
                    let day = date.getDate();
                    day = day < 10? '0' + day : day;
                    let hours = date.getHours();
                    hours = hours < 10? '0' + hours : hours;
                    let minutes = date.getMinutes();
                    minutes = minutes < 10? '0' + minutes : minutes;
                    
                                var date1 =  new Date()
                    let seconds = date1.getSeconds();
                    seconds = seconds < 10? '0' + seconds : seconds;
                    
                    const formattedDate = `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;
    	                this.bill.incomeTime =  formattedDate
                  }
             },
             afterReada(file) {
                // 此时可以自行将文件上传至服务器
                this.payInfobak.avatar = file.content;
               console.log(file);
             },
             afterReadb(file) {
                // 此时可以自行将文件上传至服务器
                this.bill.avatar = file.content;
               console.log(file);
             },
             suiji(){
                  // 生成一个介于0到1之间的随机数
               
                 this.bill.number = this.generateRandomNumber();
             },  
             suijia(){
                  // 生成一个介于0到1之间的随机数
               
               //  this.lingqiantong.data1.number = "89202"+this.generateRandomNumbera();
             },
             suijib(){
                  // 生成一个介于0到1之间的随机数
               
               //  this.lingqiantong.data2.number =  "89202"+this.generateRandomNumbera();
             },
             suijic(){
                  // 生成一个介于0到1之间的随机数
               
               //  this.lingqiantong.data3.number =  "89202"+this.generateRandomNumbera();
             },
             suijid(){
                  // 生成一个介于0到1之间的随机数
               
               //  this.lingqiantong.data4.number =  "10001"+this.generateRandomNumbera();
             },
             suijie(){
                  // 生成一个介于0到1之间的随机数
               
                // this.lingqiantong.data5.number =  "10001"+this.generateRandomNumbera();
             },
             suijif(){
                  // 生成一个介于0到1之间的随机数
               
                // this.lingqiantong.data6.number =  "10001"+this.generateRandomNumbera();
             }, 
             suijig(){
                  // 生成一个介于0到1之间的随机数
               
                // this.lingqiantong.data7.number =  "89202"+this.generateRandomNumbera();
             },
             suijih(){
                  // 生成一个介于0到1之间的随机数
               
               //  this.lingqiantong.data8.number =  "10001"+this.generateRandomNumbera();
             },
            onSubmit() {
                this.setUserInfo(this.personal)
                Dialog.alert({
                    message: '个人信息更新',
                })

            },
            onSubmitPayInfo() {
                this.setPayInfo(this.payInfobak)
                Dialog.alert({
                    message: '付款信息更新',
                })
            },
            onSubmitFund() {
                this.setFund(this.fundData)
                Dialog.alert({
                    message: '零钱/零钱通更新',
                })
            },
            delBank(id) {
                const bankList = JSON.parse(JSON.stringify(this.bankList))
                const newBankList = bankList.filter(bank => bank.id != id)
                this.setBankList(newBankList)
                Dialog.alert({
                    message: '删除银行卡成功',
                })
            },
            onSubmitNewBank() {
                const bankDefaultList = JSON.parse(JSON.stringify(this.bankDefaultList));
                const bankList = JSON.parse(JSON.stringify(this.bankList));
                const newBank = bankDefaultList.filter(bank => bank.id == this.newBankId)[0];
                let newBankNo = '';
                for(let i = 0; i < 23; i++) {
                    if (this.newBankNo && this.newBankNo[i]!== undefined) {
                        if (i!== 0 && i % 4 === 0) {
                            newBankNo += `,${this.newBankNo[i]}`;
                        } else {
                            newBankNo += `${this.newBankNo[i]}`;
                        }
                    }
                }
                this.setBankList([...bankList, {...newBank, no: newBankNo, isOut: this.isOut, id: (new Date()).getTime() }]);
                Dialog.alert({
                    message: '添加银行卡成功',
                });
            },
            delBill(id) {
                this.delBillList(id)
            },
            onSubmitNewBill() {
                const id = new Date().getTime()
                const newBill = {...this.bill}
                console.log(newBill)
                newBill.id = id
                if (this.bill.type == 1) {
                    newBill.type = '零钱'
                }
                if (this.bill.type == 2) {
                    const bank = this.bankList.filter(bill => bill.id == this.bill.bankId)[0]
                    if (bank.no) {

                        const arr = bank.no.split(',')
                          const processedStr =  bank.no.replace(/,/g, '');
                       const  asd = processedStr.slice(-4)
                        
                        newBill.type = `${bank.title}（${asd}）`
                    }
                }
                this.addBill(newBill)
            },
            onSubmitLingqiantong() {
                this.lingqiantong.data1.time = dayjs().format('YYYY-MM-DD ')+this.lingqiantong.data1.times
   
                this.lingqiantong.data2.time = dayjs().subtract(1, 'day').format('YYYY-MM-DD ')+this.lingqiantong.data2.times
                this.lingqiantong.data3.time = dayjs().subtract(2, 'day').format('YYYY-MM-DD ')+this.lingqiantong.data3.times
                this.lingqiantong.data4.time = dayjs().subtract(3, 'day').format('YYYY-MM-DD ')+this.lingqiantong.data4.times
                this.lingqiantong.data5.time = dayjs().subtract(4, 'day').format('YYYY-MM-DD ')+this.lingqiantong.data5.times
                this.lingqiantong.data6.time = dayjs().subtract(5, 'day').format('YYYY-MM-DD ')+this.lingqiantong.data6.times
                this.lingqiantong.data7.time = dayjs().subtract(6, 'day').format('YYYY-MM-DD ')+this.lingqiantong.data7.times
                this.lingqiantong.data8.time = dayjs().subtract(6, 'day').format('YYYY-MM-DD ')+this.lingqiantong.data8.times
                localStorage.setItem('lingqiantong', JSON.stringify(this.lingqiantong))
                Dialog.alert({
                    message: '零钱通明细更新',
                })
            },
            afterRead1(file) {
                console.log(file)
                this.lingqiantong.data1.avatar = file.content;
            },
            afterRead2(file) {
                   console.log(file)
                this.lingqiantong.data2.avatar = file.content;
            },
            afterRead3(file) {
                this.lingqiantong.data3.avatar = file.content;
            },
            afterRead4(file) {
                this.lingqiantong.data4.avatar = file.content;
            },
            afterRead5(file) {
                this.lingqiantong.data5.avatar = file.content;
            },
            afterRead6(file) {
                this.lingqiantong.data6.avatar = file.content;
            },
            afterRead7(file) {
                this.lingqiantong.data7.avatar = file.content;
            },
            afterRead8(file) {
                this.lingqiantong.data8.avatar = file.content;
            },
        },
    };
</script>
<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #1b1b1b;
    opacity: 1;
    color: #fff;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    div {
        flex: 1;
        margin-left: -17px;
    }
}
.bankcon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
}
.bill {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    .value {
        flex: 1;
        text-align: right;
        margin-right: 20px;
    }
}
</style>